/* Expo Arabic */
@font-face {
  font-family: "Expo Arabic";
  src: url("../assets/fonts/expo-arabic-light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Expo Arabic";
  src: url("../assets/fonts/expo-arabic-book.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Expo Arabic";
  src: url("../assets/fonts/expo-arabic-medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Expo Arabic";
  src: url("../assets/fonts/expo-arabic-semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Expo Arabic";
  src: url("../assets/fonts/expo-arabic-bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Inter */
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

/* Apply fonts based on text direction */
:root[dir="rtl"] {
  font-family: "Expo Arabic", sans-serif;
}

:root[dir="ltr"] > body {
  font-family: "Inter", sans-serif;
}
