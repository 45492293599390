@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .root-class {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --success: 137 99% 35%;
    --success-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 0.75rem;
  }

  :root {
    @apply root-class;
  }
  .light {
    @apply root-class;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 217.2 91.2% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 92% 30%;
    --destructive-foreground: 210 40% 98%;
    --success: 145.77 64.55% 43.14%;
    --success-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 224.3 76.3% 48%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow-x: hidden;
}
/* ::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: #a0aec0;
  border-radius: 20px;
} */

.blue-gradient-bg {
  background-image: linear-gradient(
    215deg,
    hsl(225deg 100% 68%) 0%,
    hsl(229deg 100% 68%) 28%,
    hsl(233deg 100% 69%) 44%,
    hsl(238deg 100% 71%) 56%,
    hsl(245deg 100% 70%) 68%,
    hsl(251deg 100% 68%) 82%,
    hsl(257deg 100% 66%) 100%
  );
}

.orange-gradient-bg {
  background-image: linear-gradient(
    45deg,
    hsl(357deg 94% 68%) 0%,
    hsl(360deg 96% 69%) 13%,
    hsl(3deg 99% 69%) 22%,
    hsl(6deg 100% 69%) 29%,
    hsl(9deg 100% 68%) 35%,
    hsl(11deg 100% 68%) 40%,
    hsl(14deg 100% 68%) 45%,
    hsl(16deg 100% 67%) 50%,
    hsl(19deg 100% 67%) 55%,
    hsl(21deg 100% 67%) 60%,
    hsl(23deg 100% 67%) 64%,
    hsl(26deg 100% 66%) 69%,
    hsl(28deg 100% 66%) 73%,
    hsl(30deg 100% 66%) 77%,
    hsl(31deg 100% 66%) 82%,
    hsl(33deg 100% 66%) 86%,
    hsl(35deg 100% 66%) 91%,
    hsl(37deg 100% 67%) 95%,
    hsl(39deg 100% 67%) 100%
  );
}

.pink-gradient-bg {
  background-image: linear-gradient(
    45deg,
    hsl(359deg 91% 66%) 0%,
    hsl(357deg 94% 67%) 22%,
    hsl(354deg 97% 67%) 33%,
    hsl(352deg 100% 68%) 41%,
    hsl(350deg 100% 68%) 48%,
    hsl(348deg 100% 68%) 53%,
    hsl(345deg 100% 68%) 58%,
    hsl(343deg 100% 68%) 63%,
    hsl(341deg 100% 68%) 67%,
    hsl(339deg 100% 68%) 70%,
    hsl(336deg 100% 69%) 74%,
    hsl(334deg 100% 69%) 77%,
    hsl(332deg 100% 69%) 80%,
    hsl(330deg 100% 70%) 83%,
    hsl(327deg 100% 70%) 85%,
    hsl(325deg 100% 71%) 88%,
    hsl(323deg 100% 71%) 91%,
    hsl(320deg 100% 72%) 93%,
    hsl(318deg 100% 72%) 95%,
    hsl(315deg 100% 73%) 98%,
    hsl(313deg 100% 74%) 100%
  );
}

::file-selector-button {
  display: none;
}

.navlink path {
  stroke: white;
}

.pagination {
  display: flex;
  /* justify-content: space-between; */
  list-style: none;
  margin-top: 20px;
  padding: 0;
}

.pagination a {
  cursor: default;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #3e59f5;
  color: #3e59f5;
  margin-left: 10px;
}

.pagination li:not(.disabled) a:hover {
  background-color: #3e59f5;
  cursor: pointer;
  color: #fff;
}

.pagination li a {
  font-weight: bold;
}

.pagination li.active a {
  color: #fff;
  background: #3e59f5;
}

.pagination li.disabled a {
  pointer-events: none;
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}

.chakra-toast__inner button {
  position: absolute;
  top: 10px;
  left: unset;
  right: unset;
  inset-inline-end: 10px;
}

.slick-track {
  display: flex !important;
}

@media (max-width: 900px) {
  .home-wave {
    display: none;
  }
  .logo-blob {
    width: 300px !important;
    position: absolute;
    top: 50 !important;
    right: 50 !important;
  }
}
@media (max-width: 1500px) {
  .home-image-blob {
    display: none;
  }
}

/*--Halloub--!*/

@tailwind base;
@tailwind components;
@tailwind utilities;

.gradient-01 {
  background-image: linear-gradient(
    270deg,
    hsl(295deg 76% 51%) 0%,
    hsl(284deg 70% 73%) 26%,
    hsl(257deg 70% 86%) 39%,
    hsl(202deg 92% 90%) 50%,
    hsl(215deg 77% 81%) 61%,
    hsl(221deg 73% 70%) 74%,
    hsl(220deg 76% 51%) 100%
  );
  filter: blur(125px);
}

.hero-gradient {
  background: linear-gradient(
    97.86deg,
    #a509ff 0%,
    #34acc7 53.65%,
    #a134c7 100%
  );
}

.gradient-02 {
  position: absolute;
  width: 200px;
  height: 438px;
  top: 0px;
  right: 0px;
  background: #7aebfb;
  filter: blur(190px);
}

.glassmorphism {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.gradient-03 {
  position: absolute;
  width: 404px;
  height: 800px;
  left: 20%;
  top: 5%;
  background: rgba(173, 113, 234, 0.35);
  filter: blur(175px);
  transform: rotate(-114.2deg);
}

.gradient-04 {
  position: absolute;
  width: 304px;
  height: 100vh;
  left: 30%;
  top: 10%;
  background: rgba(107, 131, 203, 0.75);
  filter: blur(200px);
  transform: rotate(-53.13deg);
}

.gradient-05 {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.04) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.feedback-gradient {
  position: absolute;
  width: 200px;
  height: 316px;
  left: 0px;
  top: 0px;
  background: #8d39a3;
  filter: blur(175px);
}

.footer-gradient {
  position: absolute;
  width: 500px;
  height: 100px;
  left: 37%;
  bottom: 0;
  background: #65c4fa;
  filter: blur(150px);
}

/* Hide the browser's password reveal button in Edge*/
input::-ms-reveal {
  display: none;
}

.apexcharts-text.apexcharts-datalabel-label {
  font-size: 18px;
  font-weight: 500;
}
.apexcharts-datalabel-value {
  font-size: 28px;
  font-weight: 800;
}

/* styling react simple start rating wrapper */
.style-module_starRatingWrap__q-lJC {
  display: flex !important;
  flex-direction: column !important;
  gap: 8px;
  width: fit-content;
  margin-inline: auto;
}

/* remove chakra provider default styles */

.chakra-container.css-ar-19rf74d {
  padding: 0;
  margin: 0;
  max-width: 100%;
}

.waves-pattern-bg {
  background-color: hsl(var(--primary));
  opacity: 1;
  background-image: repeating-radial-gradient(
      circle at 0 0,
      transparent 0,
      rgba(255, 255, 255, 0.04) 30px
    ),
    repeating-linear-gradient(hsl(var(--primary)), hsl(var(--primary)));
}
